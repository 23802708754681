body {
  overflow: hidden;
}

.login {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(0deg, rgb(79, 49, 28) 0%, rgb(171, 119, 79) 72%, rgb(87, 160, 45) 72%, rgb(87, 160, 45) 88%, rgb(130, 180, 83) 88%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login .logo {
  min-width: 100px;
  max-width: 500px;
}

.logo img {
  width: 100%;
  height: auto;
}

.login .form {
  min-width: 300px;
  max-width: 100%;
  flex-grow: 1;
  padding: 20px;
  text-align: center;
}

.form * {
  outline: none;
  font-family: Tahoma, Geneva, sans-serif;
  font-weight: 900;
  font-size: 1.5em;
  box-sizing: border-box;
}

.form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  color: #e1e1e1;
  background-color: #363533;
  border: 3px solid #666;
  direction: rtl;
  text-align: center;
}

.shake {
  animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.form button {
  width: 80%;
  background: #c6c6c6;
  border-top: 2px solid #f6f6f6;
  border-left: 2px solid #f6f6f6;
  border-bottom: 2px solid #656565;
  border-right: 2px solid #656565;
  padding: 20px;
  font-size: 1.5em;
  color: #4e4e4e;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.form button:hover {
  background: #308316;
  border-top-color: #41cc2c;
  border-left-color: #41cc2c;
  border-right-color: #10500b;
  border-bottom-color: #10500b;
  color: white;
}

.form button:active {
  background: #308316;
  border-top-color: #10500b;
  border-left-color: #10500b;
  border-right-color: #41cc2c;
  border-bottom-color: #41cc2c;
  color: white;
}

.food {
  background-color: rgba(0,0,0,20%);
  border-radius: 50%;
}

.player {
  position: absolute;
  border-radius: 50%;
  /* opacity: 0.5 */
  transform-origin: center center;
  transform: translate(-50%, -50%);
  transition: width .3s, height .3s;
  /* transition: all 0.3s; */

  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.player p {
  padding: 0;
  margin: 0;
  font-size: 100%;
  text-align: center;
  /* width: 100%;
  height: 100%; */
  color: white;
  /* text border black with text shadow*/
  text-shadow: 0 0 10px black;
}